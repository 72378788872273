import { StyleSheet, View, Text,Image, ImageBackground } from "react-native";
import Constants from "expo-constants";

const imgLogo = require('../assets/images/eklogia_logo_v0_.png')

export default function Header() {
  return (
    <View style={styles.header}>
      <ImageBackground source={imgLogo} resizeMode="contain" style={{width:140,height:50}}/>
    </View>
  );
}

const styles = StyleSheet.create({
  header: {
    flex: 1,
    width: "100%",
    alignItems:'center',
    justifyContent:'center',
    backgroundColor: "white",
    paddingTop: Constants.statusBarHeight,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.23,
    shadowRadius: 2.62,

    elevation: 4,
    zIndex:5
  },
  text: {
    fontSize:18,
    fontWeight:'900'
  },
});
