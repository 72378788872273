import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  View,
  Text,
  TextInput,
  TouchableOpacity,
  ImageBackground,
} from "react-native";
import { useTranslation } from "react-i18next";
import { createUser, listUsers } from "../utils/db";
import _ from "lodash";

import Header from "../Components/Header";
import Footer from "../Components/Footer";

const bgImage = require("../assets/images/bg/bg_06_resumo_v0_.jpg");

const URL_PRD = "https://smtp-server.qloudyx.pt/api/sendemail";
const URL_DEV = "http://10.0.2.2:3030/api/sendemail";

export default function Thanks({ navigation, GlobalState }) {
  const { totalScore, questions } = GlobalState;
  const { t } = useTranslation();
  let timeoutId = null

  return (
    <View style={styles.screen}>
      <Header />
      <View style={styles.body}>
        <ImageBackground
          source={bgImage}
          resizeMode="cover"
          style={{ flex: 1, justifyContent: "center",width:'100%',heigh:'100%' }}
        >
          <View
            style={{
              flex: 1,
              minWidth: "100%",
              alignSelf: "flex-start",
              alignItems: "center",
              justifyContent: "center",
              paddingLeft: 30,
              paddingRight: 30,
            }}
          >
            <Text style={styles.thanksText}>{t("thanks_text")}</Text>
            <Text style={{ display: "none" }}>
              {timeoutId = setTimeout(() => {
                navigation.navigate("Home");
              }, 5000)}
            </Text>
          </View>
        </ImageBackground>
      </View>
      <Footer navigation={navigation} timer={timeoutId} />
    </View>
  );
}

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    width: "100%",
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
  body: {
    flex: 8,
    width: "100%",
    backgroundColor: "white",
    alignItems: "center",
    justifyContent: "center",
  },
  thanksText: {
    fontSize: 26,
    fontWeight: "900",
    color: "black",
    textAlign: "center",
  },
  resultText: {
    fontSize: 26,
    fontWeight: "600",
    color: "black",
  },
  input: {
    width: 250,
    height: 44,
    padding: 10,
    marginTop: 20,
    marginBottom: 10,
    backgroundColor: "#e8e8e8",
    borderRadius: 5,
  },
  text: {
    color: "black",
  },
  btnSend: {
    alignItems: "center",
    backgroundColor: "#DDDDDD",
    padding: 10,
    borderRadius: 5,
  },
});
