import React, { useState, useEffect } from "react";
import { StyleSheet, View, ImageBackground } from "react-native";

import Header from "../Components/Header";
import Footer from "../Components/Footer";

const bgImage = require("../assets/images/bg/SB_Backgrounds9.jpg");

export default function Question06({ navigation, GlobalState }) {
  const { chosenAnswer, questions } = GlobalState;

  useEffect(() => {}, []);

  const handleChosenAnswer = (item) => {};

  return (
    <View style={styles.screen}>
      <Header />
      <View style={styles.body}>
        <ImageBackground
          source={bgImage}
          resizeMode="cover"
          style={{ flex: 1, justifyContent: "center" }}
        ></ImageBackground>
      </View>
      <Footer navigation={navigation} />
    </View>
  );
}

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  body: {
    flex: 8,
    width: "100%",
    backgroundColor: "grey",
  },
  begin: {
    backgroundColor: "#141414",
  },
  text: {
    fontSize: 24,
    fontWeight: "900",
    color: "blue",
  },
  title: {
    fontSize: 40,
    fontWeight: "900",
    color: "black",
  },
  question: {
    fontSize: 27,
    fontWeight: "600",
    color: "black",
    paddingBottom: 5,
  },
  option: { fontSize: 18, fontWeight: "400", color: "white" },
  radiobtn: { backgroundColor: "#E6E6E690", marginBottom: 6 },
});
