import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  View,
  Text,
  TextInput,
  TouchableOpacity,
  ImageBackground,
} from "react-native";
import { useTranslation } from "react-i18next";
import { createUser, listUsers } from "../utils/db";
import _ from "lodash";

import Header from "../Components/Header";
import Footer from "../Components/Footer";

const socBiblicaLogo =
  "https://storage.googleapis.com/ibep-prod.appspot.com/brand/sbp/sbp.logo.png?GoogleAccessId=firebase-adminsdk-5de4x%40ibep-prod.iam.gserviceaccount.com&Expires=2278627200&Signature=A4D2SGXRSzUeOc8SaOTB51p2MnnsogjQIL3Mg3RRJNSSMvV%2B1fWkuY8NQq1DGhxSBceGKI0KWallpBi1Idg5BIf1zip2vAwMvUhMJhbdtZ0Cwwo2MoAT7AC53uv0%2FtZyVqft3Px89vVUAa7E9F6aXk%2F0bgddHM%2F6MRaz3ZxnXb2dUepapBTh3QFl1RzeYVhdud2X%2BPr1CPj1aJ%2BD4V9AXUA0I%2BATSo7Kq%2F5GxdlWMQXiLToG4%2FRCz1VfEjuWTBFvwF2wHHEv0572E4IDbTh%2Bia9IVdxAUl6DRRUq6%2FeM4QWSvus1oq55P5u5wCOsUVPIkjF%2Ftw6IWf6%2FLya%2F61xKoA%3D%3D";

const eklogiaLogo = "https://hpdronestiles-staging.qloudyx.pt/images/Logo_eklogia.png";

const bgImage = require("../assets/images/bg/bg_06_resumo_v0_.jpg");

const URL_PRD = "https://smtp-server.qloudyx.pt/api/sendemail";
const URL_DEV = "http://10.0.2.2:3030/api/sendemail";

export default function Result({ navigation, GlobalState }) {
  const { totalScore, questions } = GlobalState;
  const { t, i18n } = useTranslation();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [response, setResponse] = useState("");
  const [isEmptyFields, setIsEmptyFields] = useState(false);

  const sendEmail = () => {
    console.log(`Name: ${name}`);
    console.log(`Email: ${email}`);
    if (name !== "" && email !== "") {
      setIsEmptyFields(false);
      const dtNow = new Date().toISOString();
      const result = createUser(email, name, totalScore, dtNow);
      const bodyTexts = _.filter(questions["suggestions"], (r) => {
        if (totalScore >= r.min && totalScore <= r.max) {
          return r;
        }
      })?.at(0);
      const resultText = _.filter(questions["results"], (r) => {
        if (totalScore >= r.min && totalScore <= r.max) {
          return r.text;
        }
      })?.at(0)?.text;

      const emailTemplate = `<html><body><p><img src="${socBiblicaLogo}" width="129px" height="48px" alt="Sociedade Biblica"/></p><h3>${t(
        bodyTexts.intro
      )}</h3><q>${t(bodyTexts.verso)}</q><br>${t(
        bodyTexts.link
      )}<br>${bodyTexts.texts
        .map((text) => {
          return text.indexOf("question") !== -1
            ? `<br><h2 style="color:#3291a8">${t(text)}</h2>`
            : text.indexOf("title") !== -1
            ? `<h4>${t(text)}</h4>`
            : `<p>${t(text)}</p>`;
        })
        .join(" ")}<p><img src="${eklogiaLogo}" width="129" height="39" alt="eklogia"/></p></body></html>`;

      fetch(URL_PRD, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: `${name} <${email}>`,
          body: emailTemplate,
        }),
      })
        .then(async (response) => {
          console.log(`Response: ${JSON.stringify(response)}`);
          if (response?.status === 200) {
            setResponse("ok");
            setResponse("");
            setName("");
            setEmail("");
            navigation.navigate("Thanks");
          }
        })
        .catch((error) => {
          console.log(`Error: ${JSON.stringify(error)}`);
        });
      console.log(result);
    } else {
      setIsEmptyFields(true);
    }

    //listUsers();
  };

  return (
    <View style={styles.screen}>
      <Header />
      <View style={styles.body}>
        <ImageBackground
          source={bgImage}
          resizeMode="cover"
          style={{ flex: 1, justifyContent: "center",width:'100%',heigh:'100%' }}
        >
          <View
            style={{
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
              paddingLeft: 30,
              paddingRight: 30,
            }}
          >
            <Text style={styles.totalScore}>
              {t("result_score")}
              {totalScore}
            </Text>
            <Text style={styles.resultText}>
              {t(
                _.filter(questions["results"], (r) => {
                  if (totalScore >= r.min && totalScore <= r.max) {
                    return r.text;
                  }
                })?.at(0)?.text ?? ""
              )}
            </Text>
            <TextInput
              value={name}
              onChangeText={(v) => setName(v)}
              placeholder={t("input_name")}
              style={styles.input}
            />
            <TextInput
              value={email}
              onChangeText={(v) => setEmail(v)}
              placeholder={t("input_email")}
              style={styles.input}
            />
            <TouchableOpacity onPress={sendEmail} style={styles.btnSend}>
              <Text style={styles.text}>{t("input_send")}</Text>
            </TouchableOpacity>
            <Text>{response !== "" ? t("email_sent") : ""}</Text>
            <Text>{isEmptyFields ? t("empty_fields") : ""}</Text>
          </View>
        </ImageBackground>
      </View>
      <Footer navigation={navigation} />
    </View>
  );
}

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    width: "100%",
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
  body: {
    flex: 8,
    width: "100%",
    backgroundColor: "white",
    alignItems: "center",
    justifyContent: "center",
  },
  totalScore: {
    fontSize: 36,
    fontWeight: "900",
    color: "black",
  },
  resultText: {
    fontSize: 26,
    fontWeight: "600",
    color: "black",
  },
  input: {
    width: 250,
    height: 44,
    padding: 10,
    marginTop: 20,
    marginBottom: 10,
    backgroundColor: "#e8e8e8",
    borderRadius: 5,
  },
  text: {
    color: "black",
  },
  btnSend: {
    alignItems: "center",
    backgroundColor: "#DDDDDD",
    padding: 10,
    borderRadius: 5,
  },
});
