import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Constants from "expo-constants";

import {
  StyleSheet,
  SafeAreaView,
  View,
  Text,
  TextInput,
  TouchableOpacity,
  FlatList,
  Image,
  ImageBackground,
} from "react-native";

import Header from "../Components/Header";
import Footer from "../Components/Footer";

const bgImage = require("../assets/images/bg/bg_00_home_v0_.png");

export default function Home({ navigation, GlobalState }) {
  const {
    score,
    setScore,
    totalScore,
    setTotalScore,
    chosenAnswer,
    setChosenAnswer,
    questions,
  } = GlobalState;
  const { t, i18n } = useTranslation();

  const startGame = (langCode) => {
    i18n.changeLanguage(langCode);
    setTotalScore(0);
    setScore(0);
    setChosenAnswer({});
    console.log(`Language chosen: ${langCode}`)
    console.log(`Score: ${score}`);
    console.log(`TotalScore: ${totalScore}`);
    console.log(`ChosenAnswer: ${JSON.stringify(chosenAnswer)}`);
    navigation.navigate("Question01");
  };

  useEffect(() => {}, []);

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.screen}>
        <Header />
        <View style={styles.body}>
          <ImageBackground
            source={bgImage}
            resizeMode="cover"
            style={{ flex: 1, justifyContent: "center" }}
          >
            <View style={{
                flex: 4.5,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}></View>
            <View
              style={{
                flex: 1,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <TouchableOpacity onPress={() => startGame("pt")}>
                <Image
                  style={styles.flag}
                  source={require("../assets/images/flags/pt.png")}
                ></Image>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => startGame("es")}>
                <Image
                  style={styles.flag}
                  source={require("../assets/images/flags/es.png")}
                ></Image>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => startGame("en")}>
                <Image
                  style={styles.flag}
                  source={require("../assets/images/flags/en.png")}
                ></Image>
              </TouchableOpacity>
              
            </View>
            <View
              style={{
                flex: 1,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <TouchableOpacity onPress={() => startGame("it")}>
                <Image
                  style={styles.flag}
                  source={require("../assets/images/flags/it.png")}
                ></Image>
              </TouchableOpacity>
             <TouchableOpacity onPress={() => startGame("fr")}>
                <Image
                  style={styles.flag}
                  source={require("../assets/images/flags/fr.png")}
                ></Image>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => startGame("pl")}>
                <Image
                  style={styles.flag}
                  source={require("../assets/images/flags/pl.png")}
                ></Image>
              </TouchableOpacity>
             
            </View>
          </ImageBackground>
        </View>
        <Footer navigation={navigation} />
      </View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: Constants.currentHeight,
  },
  screen: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  body: {
    flex: 8,
    backgroundColor: "#D7D7D7",
    width: "100%",
    alignContent: "space-around",
    justifyContent: "center",
  },
  begin: {},
  text: {
    fontSize: 24,
    fontWeight: "900",
    textTransform: "uppercase",
    color: "yellow",
  },
  flag: {
    width: 100,
    height: 100,
    borderRadius: 100 / 2,
    borderColor: "#000",
    borderWidth: 2,
    margin: 10,
  },
});
