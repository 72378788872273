import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en  from "../locales/en.json";
import es from "../locales/es.json";
import fr from "../locales/fr.json";
import it from "../locales/it.json";
import pl from "../locales/pl.json";
import pt from "../locales/pt.json";

i18n.use(initReactI18next).init({
  compatibilityJSON: "v3",
  lng: "en",
  resources: {
    en: {translation: en},
    es: {translation: es},
    fr: {translation: fr},
    it: {translation: it},
    pl: {translation: pl},
    pt: {translation: pt},
  },
  react: {
    useSuspense: false,
  },
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
