import React, { createContext } from "react";

// ** Defaults
const defaultQuestions = {
  question01: {
    title: "question01_title",
    question: "question01_question",
    options: [
      { id: 1, value: "question01_option_1", score: 30 },
      { id: 2, value: "question01_option_2", score: 40 },
      { id: 3, value: "question01_option_3", score: 50 },
      { id: 4, value: "question01_option_4", score: 0 },
    ],
  },
  question02: {
    title: "question02_title",
    question: "question02_question",
    options: [
      { id: 1, value: "question02_option_1", score: 25 },
      { id: 2, value: "question02_option_2", score: 125 },
      { id: 3, value: "question02_option_3", score: 50 },
      { id: 4, value: "question02_option_4", score: 100 },
    ],
  },
  question03: {
    title: "question03_title",
    question: "question03_question",
    options: [
      { id: 1, value: "question03_option_1", score: 60 },
      { id: 2, value: "question03_option_2", score: 30 },
      { id: 3, value: "question03_option_3", score: 15 },
      { id: 4, value: "question03_option_4", score: 0 },
    ],
  },
  question04: {
    title: "question04_title",
    question: "question04_question",
    options: [
      { id: 1, value: "question04_option_1", score: 0 },
      { id: 2, value: "question04_option_2", score: 25 },
    ],
  },
  question05: {
    title: "question05_title",
    question: "question05_question",
    options: [
      { id: 1, value: "question05_option_1", score: 0 },
      { id: 2, value: "question05_option_2", score: 10 },
      { id: 3, value: "question05_option_3", score: 20 },
      { id: 4, value: "question05_option_4", score: 25 },
    ],
  },
  results: [
    { min: 0, max: 25, text: "result_0_25" },
    { min: 26, max: 105, text: "result_26_105" },
    { min: 106, max: 200, text: "result_106_200" },
    { min: 201, max: 1000, text: "result_201_1000" },
  ],
  suggestions: [
    {
      min: 0,
      max: 25,
      intro: "email_congrats_25",
      verso: "verso_biblico",
      link:"href_link",
      texts: [],
    },
    {
      min: 26,
      max: 105,
      intro: "email_congrats_26_105",
      verso: "verso_biblico",
      link:"href_link",
      texts: [
        "question01_title",
        "accomodation_suggestion_01",
        "accomodation_suggestion_05",
        "question02_title",
        "power_supply_suggestion_02",
        "power_supply_suggestion_05_title",
        "power_supply_suggestion_05",
        "power_supply_suggestion_06_title",
        "power_supply_suggestion_06",
        "power_supply_suggestion_07_title",
        "power_supply_suggestion_07",
        "question03_title",
        "transport_suggestion_03",
        "transport_suggestion_05",
        "question04_title",
        "consumption_suggestion_03_title",
        "consumption_suggestion_03",
        "consumption_suggestion_04_title",
        "consumption_suggestion_04",
        "consumption_suggestion_07_title",
        "consumption_suggestion_07",
        "consumption_suggestion_09_title",
        "consumption_suggestion_09",
        "question05_title",
        "waste_suggestion_01_title",
        "waste_suggestion_01",
        "waste_suggestion_02",
      ],
    },
    {
      min: 106,
      max: 200,
      intro: "email_congrats_106_200",
      verso:"verso_biblico",
      link:"href_link",
      texts: [
        "question01_title",
        "accomodation_suggestion_01",
        "accomodation_suggestion_03_title",
        "accomodation_suggestion_03",
        "accomodation_suggestion_04",
        "accomodation_suggestion_05",
        "accomodation_suggestion_06",
        "question02_title",
        "power_supply_suggestion_01",
        "power_supply_suggestion_02",
        "power_supply_suggestion_04",
        "power_supply_suggestion_05_title",
        "power_supply_suggestion_05",
        "power_supply_suggestion_06_title",
        "power_supply_suggestion_06",
        "power_supply_suggestion_07_title",
        "power_supply_suggestion_07",
        "question03_title",
        "transport_suggestion_02",
        "transport_suggestion_03",
        "transport_suggestion_05",
        "question04_title",
        "consumption_suggestion_01",
        "consumption_suggestion_02",
        "consumption_suggestion_03_title",
        "consumption_suggestion_03",
        "consumption_suggestion_04_title",
        "consumption_suggestion_04",
        "consumption_suggestion_06_title",
        "consumption_suggestion_06",
        "consumption_suggestion_07_title",
        "consumption_suggestion_07",
        "consumption_suggestion_09_title",
        "consumption_suggestion_09",
        "question05_title",
        "waste_suggestion_01_title",
        "waste_suggestion_01",
        "waste_suggestion_02",
        "waste_suggestion_03",
        "waste_suggestion_05",
      ],
    },
    {
      min: 201,
      max: 1000,
      intro: "email_congrats_201_1000",
      verso:"verso_biblico",
      link:"href_link",
      texts: [
        "question01_title",
        "accomodation_suggestion_01",
        "accomodation_suggestion_02",
        "accomodation_suggestion_03_title",
        "accomodation_suggestion_03",
        "accomodation_suggestion_04",
        "accomodation_suggestion_05",
        "accomodation_suggestion_06",
        "question02_title",
        "power_supply_suggestion_01",
        "power_supply_suggestion_02",
        "power_supply_suggestion_03",
        "power_supply_suggestion_04",
        "power_supply_suggestion_05_title",
        "power_supply_suggestion_05",
        "power_supply_suggestion_06_title",
        "power_supply_suggestion_06",
        "power_supply_suggestion_07_title",
        "power_supply_suggestion_07",
        "question03_title",
        "transport_suggestion_01",
        "transport_suggestion_02",
        "transport_suggestion_03",
        "transport_suggestion_04",
        "transport_suggestion_05",
        "question04_title",
        "consumption_suggestion_01",
        "consumption_suggestion_02",
        "consumption_suggestion_03_title",
        "consumption_suggestion_03",
        "consumption_suggestion_04_title",
        "consumption_suggestion_04",
        "consumption_suggestion_05_title",
        "consumption_suggestion_05",
        "consumption_suggestion_06_title",
        "consumption_suggestion_06",
        "consumption_suggestion_07_title",
        "consumption_suggestion_07",
        "consumption_suggestion_08_title",
        "consumption_suggestion_08",
        "consumption_suggestion_09_title",
        "consumption_suggestion_09",
        "question05_title",
        "waste_suggestion_01_title",
        "waste_suggestion_01",
        "waste_suggestion_02",
        "waste_suggestion_03",
        "waste_suggestion_03",
        "waste_suggestion_05",
      ],
    },
  ],
};

const Questions = createContext(defaultQuestions);

export { Questions };
