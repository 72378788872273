import React, { useState, useEffect } from "react";

import {
  StyleSheet,
  View,
  TextInput,
  TouchableOpacity,
  FlatList,
  Image,
  ImageBackground,
} from "react-native";

import { RadioButton, Text } from "react-native-paper";
import { useTranslation } from "react-i18next";

import Header from "../Components/Header";
import Footer from "../Components/Footer";

const bgImage = require("../assets/images/bg/bg_01_alojamento_v0_.jpg");
const eklogiaLogo = require("../assets/images/Logo_eklogia.png");

export default function Question01({ navigation, GlobalState }) {
  const {
    chosenAnswer,
    setChosenAnswer,
    score,
    setScore,
    totalScore,
    setTotalScore,
    questions,
  } = GlobalState;
  const { t, i18n } = useTranslation();
  const SCREEN_TITLE = "question01";

  const renderItem = (item, i) => {
    return (
      <RadioButton.Item
        key={i}
        label={t(item.value)}
        value={item.score}
        style={styles.radiobtn}
      />
    );
  };

  const handleChosenAnswer = (value) => {
    setScore(value);
    setTotalScore(totalScore + value);
    setTimeout(() => {
      console.log("Navigating to Question 02...");
      navigation.navigate("Question02");
    }, 10);
  };

  useEffect(() => {
    setScore(-1);
    console.log(`Question Score: ${score}`);
    console.log(`TotalScore: ${totalScore}`);
  }, []);

  return (
    <View style={styles.screen}>
      <Header />
      <View style={styles.body}>
        <ImageBackground
          source={bgImage}
          resizeMode="cover"
          style={{ flex: 1, justifyContent: "center" }}
        >
          <View
            style={{
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
              paddingLeft: 30,
              paddingRight: 30,
            }}
          >
            <View style={{ flex: 3, alignItems: "center",justifyContent:'center' }}>
              <Image
                source={eklogiaLogo}
                resizeMode="contain"
                style={{ width: 180, height: 50, textAlign: "center" }}
              />
            </View>
            <View style={{flex:3,alignItems:'flex-start'}}>
              <Text style={styles.title}>
                {t(questions[SCREEN_TITLE].title)}
              </Text>
              <Text style={styles.question}>
                {t(questions[SCREEN_TITLE].question)}
              </Text>
            </View>

            <View style={{flex:6}}>
              <RadioButton.Group
                onValueChange={(newValue) => handleChosenAnswer(newValue)}
                value={score}
              >
                <RadioButton.Item
                  label="NotApplicable"
                  value="-1"
                  style={{ display: "none" }}
                />
                {questions[SCREEN_TITLE].options.map((item, i) =>
                  renderItem(item, i)
                )}
              </RadioButton.Group>
            </View>
          </View>
        </ImageBackground>
      </View>
      <Footer navigation={navigation} />
    </View>
  );
}

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    alignItems: "center",
  },
  body: {
    flex: 8,
    width: "100%",
    backgroundColor: "white",
  },
  begin: {
    backgroundColor: "#141414",
  },
  text: {
    fontSize: 24,
    fontWeight: "900",
    color: "black",
  },
  title: {
    fontSize: 40,
    fontWeight: "900",
    textAlign: "center",
    color: "black",
  },
  question: {
    fontSize: 27,
    fontWeight: "600",
    color: "black",
    paddingBottom: 5,
  },
  option: {
    fontSize: 18,
    fontWeight: "400",
  },
  radiobtn: { backgroundColor: "#E6E6E690", marginBottom: 6 },
});
