import * as SQLite from "expo-sqlite";

const db = SQLite.openDatabase("eklogia.db");

//create table function
const createUserTable = () => {
  db.transaction((tx) => {
    tx.executeSql(
      "CREATE TABLE IF NOT EXISTS users (id INTEGER PRIMARY KEY AUTOINCREMENT, email VARCHAR, name VARCHAR, score VARCHAR, created_on DATETIME)"
    );
  });
};

//insert a new user record
const createUser = (email, name, score, created_on) => {
  let sql = "INSERT INTO users (email, name, score, created_on) VALUES (?, ?, ?, ?)";
  let params = [email, name, score, created_on];
  db.transaction((tx) => {
    tx.executeSql(
      sql,
      params,
      (result) => {
        console.log(`SQLite: createUser: result: ${JSON.stringify(result)}`);
        return result
      },
      (error) => {
        console.log(`SQLite: createUser: error: ${JSON.stringify(error)}`);
      }
    );
  });
};

//list all the users
const listUsers = async () => {
  let sql = "SELECT * FROM users";
  db.transaction((tx) => {
    tx.executeSql(
      sql,
      [],
      (tx, resultSet) => {
        var length = resultSet.rows.length;
        for (var i = 0; i < length; i++) {
          console.log(resultSet.rows.item(i));
        }
      },
      (error) => {
        console.log("List user error", error);
      }
    );
  });
};

//update user record
const updateUser = (email, name, score, created_on, id) => {
  let sql = "UPDATE users SET email = ?, name = ?, score = ?, created_on = ? WHERE id = ?";
  let params = [email, name, score, created_on, id];
  db.transaction((tx) => {
    tx.executeSql(
      sql,
      params,
      (resultSet) => {
        console.log(`${resultSet}`);
      },
      (error) => {
        console.log(error);
      }
    );
  });
};

//delete user record
const deleteUser = (id) => {
  let sql = "DELETE FROM users WHERE id = ?";
  let params = [id];
  db.transaction((tx) => {
    tx.executeSql(
      sql,
      params,
      (resultSet) => {
        console.log("User deleted successfully");
      },
      (error) => {
        console.log("Delete user error", error);
      }
    );
  });
};
export { db, createUserTable, createUser, listUsers, updateUser, deleteUser };
