import React, { useState, useEffect, useContext } from "react";
import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";

//database connection
import * as SQLite from "expo-sqlite";

const db = SQLite.openDatabase("eklogia.db");

import "./src/utils/i18n";

import {
  createUserTable,
  createUser,
  listUsers,
  updateUser,
  deleteUser,
} from "./src/utils/db";

import Home from "./src/screens/Home";
import Question01 from "./src/screens/Question01";
import Question02 from "./src/screens/Question02";
import Question03 from "./src/screens/Question03";
import Question04 from "./src/screens/Question04";
import Question05 from "./src/screens/Question05";
import Question06 from "./src/screens/Question06";
import Result from "./src/screens/Result";
import Thanks from "./src/screens/Thanks";

import { Questions } from "./src/Context/Questions";

const Stack = createNativeStackNavigator();

export default function App() {
  // Global State management
  const [score, setScore] = useState(0);
  const [totalScore, setTotalScore] = useState(0);
  const [chosenAnswer, setChosenAnswer] = useState({});

  const questionsCtx = () => useContext(Questions);

  const questions = questionsCtx();

  const GlobalState = {
    score,
    setScore,
    totalScore,
    setTotalScore,
    chosenAnswer,
    setChosenAnswer,
    questions,
  };

  useEffect(() => {
    createUserTable();
  }, []);
  // navigation

  return (
    <NavigationContainer>
      <Stack.Navigator>
        <Stack.Screen name="Home" options={{ headerShown: false }}>
          {(props) => <Home {...props} GlobalState={GlobalState} />}
        </Stack.Screen>

        <Stack.Screen name="Question01" options={{ headerShown: false }}>
          {(props) => <Question01 {...props} GlobalState={GlobalState} />}
        </Stack.Screen>

        <Stack.Screen name="Question02" options={{ headerShown: false }}>
          {(props) => <Question02 {...props} GlobalState={GlobalState} />}
        </Stack.Screen>

        <Stack.Screen name="Question03" options={{ headerShown: false }}>
          {(props) => <Question03 {...props} GlobalState={GlobalState} />}
        </Stack.Screen>

        <Stack.Screen name="Question04" options={{ headerShown: false }}>
          {(props) => <Question04 {...props} GlobalState={GlobalState} />}
        </Stack.Screen>

        <Stack.Screen name="Question05" options={{ headerShown: false }}>
          {(props) => <Question05 {...props} GlobalState={GlobalState} />}
        </Stack.Screen>

        <Stack.Screen name="Question06" options={{ headerShown: false }}>
          {(props) => <Question06 {...props} GlobalState={GlobalState} />}
        </Stack.Screen>

        <Stack.Screen name="Result" options={{ headerShown: false }}>
          {(props) => <Result {...props} GlobalState={GlobalState} />}
        </Stack.Screen>

        <Stack.Screen name="Thanks" options={{ headerShown: false }}>
          {(props) => <Thanks {...props} GlobalState={GlobalState} />}
        </Stack.Screen>
      </Stack.Navigator>
    </NavigationContainer>
  );
}
